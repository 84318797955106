/* eslint-disable no-useless-escape */
export const ACCOUNT_ID = /^\d{5,12}$/

export const ACTIVATION_CODE = /^(\d{6}|\d{8})$/

export const TRANSFER_CODE = /^[0-9]{10,32}$/

export const BANK_ID = /^\d{8}$/

export const BIC = /^([A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?)$/

export const CAPTCHA = /^([a-z0-9]{5})$/

export const CASHCODE_VOUCHER = /^(\d{12}|\d{16})$/

export const EMAIL =
  /^[_A-Za-z0-9\-+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/

export const IBAN = /^[A-Z]{2}[0-9]{2,2}[A-Z0-9]{11,31}$/i

export const JS_BIC = /^([a-zA-Z]{6}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?)$/

export const JS_IBAN = /^[A-Z]{2}[0-9]{2,2}[A-Z0-9]{11,31}$/i

export const MSISDN = /^(0|0049|\+49)[0-9\-\s]{10,12}$/

// Only characters from VALID_CHARS, plus whitespace, as long as it does not appear at the beginning or the end.
export const NAME =
  /^[a-zA-ZüÜäÄöÖßŠŽ‘’–šž´ÀÁÂÃÇÈÉÊÌÍÎÏÑÒÓÔÕøßàáâçèéêìíîñòóôùúû,./,-/,'](\s*[a-zA-ZüÜäÄöÖßŠŽ‘’–šž´ÀÁÂÃÇÈÉÊÌÍÎÏÑÒÓÔÕøßàáâçèéêìíîñòóôùúû,./,-/,'])*$/

// Extract options from string
export const OPTIONS = /<option>(.*?)<\/option>/g

export const RE_ENTRY_ID = /^[0-9]{18,18}$/

export const STREET_NUMBER =
  /^[0-9]{1}[0-9]{0,3}([a-zA-Z]{1})?([-|/][1-9]{1}[0-9]{0,3})?([a-zA-Z]{1})?$/

export const TELEPHONE = /^(0|0049|\+49)[0-9\-\s]{9,20}$/

export const TEXT =
  /^[0-9a-zA-Z !&'()+,\-./:;=@^`‚ÀÁÂÃÄÇÈÉÊÌÍÎÏÑÒÓÔÕÖÜøßàáâäçèéêìíîñòóôöùúûüŠŽšž]*$/

export const LONGTEXT =
  /^[0-9a-zA-Z !\?•&'()+,\-./:;=@^`‚ÀÁÂÃÄÇÈÉÊÌÍÎÏÑÒÓÔÕÖÜøßàáâäçèéêìíîñòóôöùúûüŠŽšž\n\r]*$/

export const ZIP_CODE = /^\d{5}$/

export const PASSWORD =
  /^((?=.*[A-Za-z])(?=.*\d)|(?=.*[A-Za-z])(?=.*[!@#$%^&*()_+\-=\[\]{};\':"\|,.<>\/?])|(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};\':"\|,.<>\/?]))[A-Za-z\d!@#$%^&*()_+\-=\[\]{};\':"\|,.<>\/?]{11,20}$/

export const JPG = /(jpg|jpeg)$/

export const GIF = /gif$/

export const PNG = /png$/

export const ICO = /ico$/

export const SVG = /svg$/

export const URL =
  /^((http|ftp|https):)*\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/
