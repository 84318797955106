import {
  ACTIVATION_CODE,
  CAPTCHA,
  CASHCODE_VOUCHER,
  LONGTEXT,
  MSISDN,
  NAME,
  PASSWORD,
  RE_ENTRY_ID,
  STREET_NUMBER,
  TELEPHONE,
  TEXT,
  TRANSFER_CODE,
  ZIP_CODE
} from './regexp'

import { isValidGermanBirthDate } from './date'
import { sanitizePhonenumber } from 'src/lib/sanitizer'

export const ruleRequired = value => !!value || 'Bitte ausfüllen'

export const ruleIsEmpty = value => !value || 'Darf nicht ausgefüllt werden'

export const ruleZipCode = value => ZIP_CODE.test(value) || 'Ungültige PLZ'

export const ruleStreetNumber = value =>
  STREET_NUMBER.test(value) || 'Ungültige Hausnr.'

export const ruleBirthDate = value =>
  isValidGermanBirthDate(value) || 'Ungültiges Geburtsdatum. Bsp: 24.12.1994'

export const ruleEmail = value => {
  const pattern = /^\S+@\S+$/

  return pattern.test(value) || 'Ungültige E-Mail-Adresse'
}

export const ruleReEntryId = value => RE_ENTRY_ID.test(value)

export const rulePhoneNumber = value =>
  value ? TELEPHONE.test(value) || 'Ungültige Telefonnummer' : true

export const ruleMsisdn = value =>
  value
    ? MSISDN.test(sanitizePhonenumber(value)) || 'Ungültige Mobilfunknummer'
    : true

export const ruleMsisdnOptional = value =>
  value
    ? MSISDN.test(sanitizePhonenumber(value)) || 'Ungültige Mobilfunknummer'
    : true

export const ruleName = (value, minLength, maxLength) => {
  if ((value === undefined || value === null) && minLength <= 0) {
    return true
  }

  if (value && value.length < minLength) {
    return `Bitte geben Sie mindestens ${minLength} Zeichen ein`
  }

  if (value && value.length > maxLength) {
    return `Ungültige Eingabe: zu lang`
  }

  return NAME.test(value) || 'Eingabe enthält ungültige Zeichen'
}

export const ruleText = (value, minLength, maxLength) => {
  if ((value === undefined || value === null) && minLength <= 0) {
    return true
  }

  if (value && value.length < minLength) {
    return `Bitte geben Sie mindestens ${minLength} Zeichen ein`
  }

  if (value && value.length > maxLength) {
    return `Ungültige Eingabe: zu lang`
  }

  return TEXT.test(value) || 'Eingabe enthält ungültige Zeichen'
}

export const ruleLongText = (value, minLength, maxLength) => {
  if (value && value.length < minLength) {
    return `Bitte schreiben Sie eine Nachricht mit mindestens ${minLength} Zeichen`
  }

  if (value && maxLength && value.length > maxLength) {
    return 'Ungültige Eingabe: zu lang'
  }

  return LONGTEXT.test(value) || 'Eingabe enthält ungültige Zeichen'
}

export const ruleCaptcha = value =>
  CAPTCHA.test(value) || 'Nur Zahlen und kleine Buchstaben'

export const ruleActivationCode = value =>
  ACTIVATION_CODE.test(value) || 'Ungültiger Aktivierungscode'

export const ruleSimCardTransferId = value =>
  TRANSFER_CODE.test(value) || 'Ungültiger Besitzerwechsel-Code'

export const ruleVoucherCode = value =>
  CASHCODE_VOUCHER.test(value) || 'Ungültige Aufladenummer.'

export const ruleCashCode = value =>
  CASHCODE_VOUCHER.test(value) || 'Ungültiger CashCode'

export const rulePassword = (value, minLength, maxLength) => {
  if ((value === undefined || value === null) && minLength <= 0) {
    return true
  }

  if (value && value.length < minLength) {
    return `Wählen Sie ein Passwort mit mindestens ${minLength} Zeichen.`
  }

  if (value && value.length > maxLength) {
    return `Wählen Sie ein Passwort mit maximal ${maxLength} Zeichen.`
  }

  return PASSWORD.test(value) || 'Ihr Passwort erfüllt nicht alle Anforderungen'
}

export const ruleRadioHasValue = value =>
  (value !== undefined && value !== null) ||
  'Bitte wählen Sie eine der vorhandenen Optionen'
